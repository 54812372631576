<template>
  <section class="section section_program">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show">
            Трансляция конференции
          </h2>

        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12 col-lg-3">
          <div class="program" v-for="day in getTelecastingWithSection" :key="day.id">
            <div class="program__items" v-for="section in day.sections" :key="section.id">
              <div class="program__head" @click="changeActive(section.id)"
                   :class="section.id === active ? 'active' : ''">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div class="program__area">
                        {{ section.name }}
                      </div>
                      <div class="program__themes">
                        {{ section.theme }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-5 d-none d-lg-block">
            <rasp-slider></rasp-slider>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <telecast-component :telecast="getActiveTelecast" v-if="active"></telecast-component>
          <div class="pt-3 d-block d-lg-none">
            <rasp-slider></rasp-slider>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import TelecastComponent from "../components/Telecast/TelecastComponent";
import RaspSlider from "../components/components/RaspSlider";

export default {
  name: "Telecast",
  components: {RaspSlider, TelecastComponent},
  data: () => ({
    telecast: [
      {
        "id": 1,
        "link": "https://facecast.net/v/yhj8sg?nodate&nolabel&noanimation",
        "preview": "/images/Заглушки.png",
        "hasChat": true,
        "createdAt": "2023-04-23T17:07:40.000Z",
        "updatedAt": "2023-04-24T10:25:55.000Z",
        "day_id": 1,
        "section_id": 1,
        "programSection": {
          "id": 1,
          "name": "Пленарная секция",
          "theme": "Трансформация рынка и бизнеса",
          "order": 1,
          "createdAt": "2023-03-14T20:43:45.000Z",
          "updatedAt": "2023-04-10T09:54:45.000Z"
        },
        "programDay": {
          "id": 1,
          "name": "День 1",
          "createdAt": "2023-03-14T20:42:53.000Z",
          "updatedAt": "2023-03-14T20:42:53.000Z"
        }
      },
      {
        "id": 2,
        "link": "https://facecast.net/v/cxvlbt?nodate&nolabel&noanimation",
        "preview": "/images/Заглушки-1.png",
        "hasChat": true,
        "createdAt": "2023-04-23T17:08:16.000Z",
        "updatedAt": "2023-04-24T10:26:19.000Z",
        "day_id": 1,
        "section_id": 2,
        "programSection": {
          "id": 2,
          "name": "Секция 1",
          "theme": "Экосистема решений и практика применения ",
          "order": 2,
          "createdAt": "2023-03-14T20:44:31.000Z",
          "updatedAt": "2023-04-24T08:39:17.000Z"
        },
        "programDay": {
          "id": 1,
          "name": "День 1",
          "createdAt": "2023-03-14T20:42:53.000Z",
          "updatedAt": "2023-03-14T20:42:53.000Z"
        }
      },
      {
        "id": 3,
        "link": "https://facecast.net/v/om3egs?nodate&nolabel&noanimation",
        "preview": "/images/Заглушки-2.png",
        "hasChat": true,
        "createdAt": "2023-04-23T17:09:08.000Z",
        "updatedAt": "2023-04-24T10:27:27.000Z",
        "day_id": 1,
        "section_id": 3,
        "programSection": {
          "id": 3,
          "name": "Секция 2",
          "theme": "Экосистема решений и практика применения ",
          "order": 3,
          "createdAt": "2023-03-14T20:45:11.000Z",
          "updatedAt": "2023-04-10T09:54:43.000Z"
        },
        "programDay": {
          "id": 1,
          "name": "День 1",
          "createdAt": "2023-03-14T20:42:53.000Z",
          "updatedAt": "2023-03-14T20:42:53.000Z"
        }
      },
      {
        "id": 4,
        "link": "https://facecast.net/v/ycvm0v?nodate&nolabel&noanimation",
        "preview": "/images/Заглушки-3.png",
        "hasChat": true,
        "createdAt": "2023-04-23T17:09:44.000Z",
        "updatedAt": "2023-04-24T10:27:44.000Z",
        "day_id": 1,
        "section_id": 4,
        "programSection": {
          "id": 4,
          "name": "Секция 3",
          "theme": "Экосистема решений и практика применения ",
          "order": 4,
          "createdAt": "2023-03-14T20:46:22.000Z",
          "updatedAt": "2023-04-10T09:54:42.000Z"
        },
        "programDay": {
          "id": 1,
          "name": "День 1",
          "createdAt": "2023-03-14T20:42:53.000Z",
          "updatedAt": "2023-03-14T20:42:53.000Z"
        }
      },
      {
        "id": 5,
        "link": "https://facecast.net/v/7l6swi?nodate&nolabel&noanimation",
        "preview": "/images/Заглушки-4.png",
        "hasChat": true,
        "createdAt": "2023-04-23T17:10:23.000Z",
        "updatedAt": "2023-04-24T10:28:04.000Z",
        "day_id": 1,
        "section_id": 5,
        "programSection": {
          "id": 5,
          "name": "Секция 4",
          "theme": "Экосистема решений и практика применения",
          "order": 5,
          "createdAt": "2023-03-15T17:21:13.000Z",
          "updatedAt": "2023-03-15T17:21:13.000Z"
        },
        "programDay": {
          "id": 1,
          "name": "День 1",
          "createdAt": "2023-03-14T20:42:53.000Z",
          "updatedAt": "2023-03-14T20:42:53.000Z"
        }
      }
    ],
    active: null,
  }),
  methods: {
    changeActive(id) {
      this.active = id
    }
  },
  computed: {
    getSections() {
      return this.$store.getters.getProgram
    },
    getQuerySection() {
      return this.$route.query.section
    },
    getTelecastingWithSection() {
      if (this.telecast.length) {
        let arr = []
        _.forEach(this.getSections, (day) => {
          let dayToArr = {
            id: day.id,
            name: day.name,
            order: day.order,
            sections: []
          }
          _.forEach(day.sections, (section) => {
            let sectionToArr = {
              id: section.id,
              name: section.name,
              theme: section.theme,
              order: section.order
            }
            sectionToArr.telecast = _.find(this.telecast, telecast => telecast.section_id === section.id && telecast.day_id === day.id).id || undefined
            dayToArr.sections.push(sectionToArr)
          })
          arr.push(dayToArr)
        })
        return arr
      }
      return []
    },
    getActiveSection() {
      return _.find(this.getSections[0].sections, section => section.id === this.active)
    },
    getActiveTelecast() {
      return _.find(this.telecast, telecast => telecast.section_id === this.active)
    }
  },
  watch: {
    getTelecastingWithSection(val) {
      this.active = parseInt(this.$route.query.section) || val[0].sections[0].id
    },
    getQuerySection(val) {
      if (val) {
        this.active = val
      }
    }
  },
  mounted() {
    // this.$axios.get('/telecast')
    //     .then(resp => {
    //       this.telecast = resp.data
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     })
  }
}
</script>

<style scoped lang="scss">
.program {

  &__head {
    background: #E7E7E7;
    border: 1px solid #E4F5FF;
    border-radius: 10px;
    box-sizing: border-box;
    padding: rem(10) 0;
    font-size: rem(16);
    font-weight: 300;
    cursor: pointer;
    min-height: 94px;
    display: flex;
    align-items: center;
    margin-bottom: rem(5);
    @include media(992) {
      min-height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active {
      background: #0079C1;
      color: #fff;
    }

  }

  &__area {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__themes {
    //text-transform: lowercase;
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__time {
    color: #00476D;
    font-size: rem(24);
    font-weight: bold;
  }

  &__text {

  }

  &__item {
    border-bottom: 2px solid #ECECF1;

  }

  &__h {
    padding: rem(15) 0;
    cursor: pointer;
    position: relative;
    @include media(768) {
      svg {
        position: absolute;
        top: 20px;
        right: 10px;
      }

    }
  }

  &__name {
    font-size: rem(18);
    font-weight: 300;
    color: #282938;
  }

  &__text {
    background: #FFFBF2;
    border-radius: 10px;
    padding: rem(10) 0;
    margin-bottom: rem(10);
  }

  &__theme {
    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
      }

      @include media(768) {
        padding: 0 0px;
      }
    }
  }


}
</style>
